#intro {
    width: 100vw;
    max-width: 75rem;
    margin: 0 auto;
    position: relative;
    padding-bottom: 2rem;
}

.introContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 2rem;
    margin-top: 220px;
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
}

.bg {
    position: absolute;
    top: 0%; /* Position the image higher */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -90%); /* Center without affecting vertical position */
    height: 240px; /* Adjust size for smaller screens */
    width: 240px; /* Keep it square for a circle */
    object-fit: cover;
    z-index: -1;
    border-radius: 50%;
    border: 5px solid #00C2F3;
    box-sizing: border-box;
}

.hello {
    font-size: 1.75rem;
    font-weight: 300;
}

.introText {
    font-size: 3rem;
    font-weight: 400;
}

.introName {
    color: #00C2F3;
    text-shadow: 2px 2px 10px rgba(0, 194, 243, 0.5); /* Glowing effect */
}

.fade-in {
    opacity: 1;
    transition: opacity 0.5s ease-in;
}

/* Fade-out effect */
.fade-out {
    opacity: 0;
    transition: opacity 0.5s ease-out;
}


.introPara {
    font-size: large;
    font-weight: 300;
    line-height: 1.8;
}

.btn {
    background: #00C2F3;
    margin: 1rem 0;
    padding: 10px 20px;
    border: none;
    border-radius: 2rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
}

.btn:hover {
    transform: scale(1.10); 
    background: linear-gradient(90deg, #0077F3, #00C2F3); /* Reverse gradient */
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2); /* Add deeper shadow */
}

.btnImg {
    object-fit: cover;
    height: 2rem;
    margin: 0;
}

.btnText {
    font-size: 18px;
    font-weight: bold;
}
