.navbar {
    background: rgb(30,30,30);
    height: 5rem;
    width: 100%;
    margin: 0 auto;  
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 3;
}

.logo{
    object-fit: cover;
    height:4rem;
    width: 10rem;
}

.desktopMenuListItem {
    margin: 1rem;
    cursor: pointer;
}

.desktopMenuListItem:hover {
    color:#00C2F3;
    padding-bottom: 0.5 rem;
    border-bottom: 3px solid #00C2F3;
}

.desktopMenuImg {
    object-fit: cover;
    height: 1.5rem; 
    width: 1.5rem; 
    margin: 0; 
    display: inline-block; 
}

.desktopMenuBtn {
    background-color: white;
    color: black;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.3rem;
    padding: 0.5rem 1rem;
    border-radius: 2rem;
    cursor: pointer;
}

.desktopMenuBtn:hover {
    background-color: #00C2F3; /* Change to your desired color */
    font-weight: bold; /* Make text bold */
    transform: scale(1.05); 
}

.mobMenu {
    width: 50px;
    height: 50px;
    display:none;
    object-fit: cover;
  }
  
.navMenu {
    position: absolute;
    top: 4rem;
    right: 2rem;
    z-index: 2;
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    height: fit-content;
    min-width: 15rem;
    background: rgb(40, 40, 40);
    border-radius: 1rem;
  }
  
  .listItem {
    color: #00C2F3;
    padding: 0.5rem 3rem;
    margin: 0.25rem;
    background: rgb(30, 30, 30);
    border-radius:5px;
  }
  
  * {
    max-width: 100vw;
    box-sizing: border-box;
}

html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
}

#root, /* Assuming you're using React */
.App, /* Or your main app wrapper */
main {
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
    margin: 0;
    padding: 0;
}


  @media screen and (max-width: 820px) {
    * {
        max-width: 100vw;
        box-sizing: border-box;
    }
    
    html, body {
        margin: 0;
        padding: 0;
        width: 100%;
        max-width: 100%;
        overflow-x: hidden;
    }

    #root, /* Assuming you're using React */
    .App, /* Or your main app wrapper */
    main {
        width: 100%;
        max-width: 100%;
        overflow-x: hidden;
        margin: 0;
        padding: 0;
    }

    .mobMenu {
        display: flex;
        justify-content: flex-end;
        margin-right: 20px;
        cursor: pointer;
    }

    .navbar {
        height: 3.5rem;
        width: 100%;
        margin: 0 auto;  
        padding: 1rem 2rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        top: 0; /* Stick it to the top */
      }
      
      #intro {
        margin-top: 300px !important; /* Adjust margin for the background image */
      }
      
    .desktopMenu,
    .desktopMenuBtn {
        display: none;
    }
}
  
  