#experience {
    margin-top: 100px;
    text-align: center; /* Centers inline and text elements */
    display: flex; /* Enables flexbox for alignment */
    flex-direction: column; /* Stacks child elements vertically */
    align-items: center; /* Centers child elements horizontally */
}

.experienceTitle {
    margin: 1rem 0;
    font-size: 3rem;
}

.titleDivider {
    border: none;
    border-top: 1px solid #00C2F3;
    margin: 10px auto; /* Center it horizontally */
    width: 1400px; /* Adjust the width as needed */
    display: block; /* Ensure block-level behavior */
    box-sizing: border-box; /* Prevent unexpected sizing issues */
}



#companies {
    display: flex;
    justify-content: center;
}

.companyImgs {
    margin: 20px 10px;
    cursor: pointer;
    transition: transform 0.3s ease, opacity 0.3s ease;
    opacity: 0.7;
}

.companyImg {
    border-radius: 5px;
    width: 300px;
    height: 83px;
    transition: transform 0.3s ease, box-shadow 0.3s ease, border 0.3s ease;
    border: 2px solid transparent;
}

.companyImgs:hover, .companyImgs.active {
    transform: scale(1.15);
    opacity: 1;
}

.companyImg:hover, .companyImgs.active .companyImg {
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    transform: translateY(-2px);
    border: 4px solid #2E5FF2;
}

.companyInfo {
    max-width: 1000px;
    border-radius: 8px;
    padding: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 10px auto;
    text-align: left;
    transition: all 0.3s ease;
}

.companyPosition {
    font-size: 1.2rem;
    margin: 0;
}

.companyDescription {
    font-size: 1rem;
    line-height: 1.8;
    margin: 0.5rem 0;
}

.divider {
    border: none;
    border-top: 1px solid #00C2F3;
    margin: 10px 0;
}

/* Add this new style for the hover instruction */
.hover-instruction {
    font-style: italic;
    color: #666;
    margin-bottom: 1rem;
    font-size: 0.9rem;
}

/* Add this media query to handle smaller screens */
@media (max-width: 968px) {
    #companies {
        flex-direction: column; /* Stack the logos vertically */
        align-items: center; /* Center-align the items */
    }

    .companyImgs {
        margin: 10px 0; /* Adjust margins for vertical spacing */
    }

    .companyImg {
        width: 250px; /* Resize the logos for smaller screens */
        height: auto; /* Maintain aspect ratio */
    }
}
